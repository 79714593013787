<template>
    <LiefengContent :isBack="$route.query.isLineHome || $route.query.isback" @backClick="backClick">
        <template v-slot:title>
            网格管理
            <!-- <Button type="error" v-if="$route.query.isLineHome && !$route.query.isback " @click="$router.push('/linehomepage')">返回</Button>
       <Button type="error" v-if="$route.query.isLineHome && $route.query.isback" @click="$router.push(`/mobilizationsystem?isLineHome=${$route.query.isLineHome}`)">返回</Button> -->
        </template>
        <template v-slot:toolsbarRight>
            <Form :label-colon="true" :inline="true" class="search">
                <FormItem>
                    <Input :maxlength="20" enter-button v-model.trim="searchData.groupName" placeholder="请输入网格员" style="margin-right:10px;width: 150px" />
                </FormItem>
                <FormItem>
                    <LiefengCascaderCity :orgCode="4401" :resetNum="0" @changeCasader="changeCasder" :value="orgCodeValue" :isShow="!!orgCodeValue"></LiefengCascaderCity>
                </FormItem>
                <FormItem>
                    <Select transfer style="width:150px" placeholder="是否建群" v-model="searchData.createdGroup">
                        <Option :value="item.value" v-for="(item, index) in createdGroupList" :key="index">{{ item.label }}</Option>
                    </Select>
                </FormItem>
                <Button type="primary" style=" margin-right:10px" icon="ios-search" @click="searchGroupName">搜索</Button>
                <Button type="info" style="color: #fff;margin-right:10px" @click="reset" icon="ios-refresh">重置</Button>
                <Button style="margin-right:10px" @click="addGrid" type="success" icon="ios-add">新增网格</Button>
                <Button style="margin-right:10px" @click="addGridAdmin" type="success" icon="ios-add">创建第二工作群</Button>
                <Button style="margin-right:10px" @click="addGridGroup" type="success" icon="ios-add">创建网格沟通组</Button>
                <Button style="margin-right:10px" type="primary" @click="openConstruction">网格建设总览</Button>
            </Form>

            <!-- <LiefengCascaderOne
        :isTrue="selectIsTrue"
        class="cascader"
        :cityOper="selectCityOper"
        @changeLocal="changeSelectLocal"
      ></LiefengCascaderOne> -->
        </template>
        <template v-slot:contentArea>
            <LiefengTable
                :talbeColumns="talbeColumns"
                :tableData="tableData"
                :loading="loading"
                :fixTable="true"
                :curPage="curPage"
                :total="total"
                :page-size="pageSize"
                :pagesizeOpts="[20, 30, 50, 100]"
                @hadlePageSize="hadlePageSize"
                @tableSelect="tableSelect"
            ></LiefengTable>

            <!--新增网格模态框-->
            <LiefengModal :title="isEdit ? '修改网格' : '新增网格'" :value="addModel.addGridStatus" @input="addGridStatusFn" class="group" :fullscreen="true">
                <template v-slot:contentarea>
                    <Addgrid :showIcon="showIcon" :editType="editType" :isTrue="isTrue" :cityOper="cityOper" :codeList="codeList"></Addgrid>
                </template>
                <template v-slot:toolsbar>
                    <Button
                        type="info"
                        style="margin-right: 10px"
                        @click="
                            addModel.cancelGridTip = true
                            addGridStatusFn(false)
                        "
                    >
                        取消
                    </Button>
                    <Button type="primary" @click="saveGrid">确定</Button>
                </template>
            </LiefengModal>

            <!--设置网格边界-->
            <LiefengModal title="设置网格边界" class="group" :value="setNetwork.status" @input="closeNetwork" :fullscreen="true">
                <template v-slot:contentarea>
                    <Tree
                        @selectTree="selectTree"
                        :dscString="dscString"
                        :orgCode="treeOrgCode"
                        :gridId="gridId"
                        :showNum="showNum"
                        :type="gridType"
                        @tableSelect="tableSelectTree"
                        @changeRadio="changeRadio"
                        ref="treeChild"
                    ></Tree>
                    <!-- :selectList="selectListArr" -->
                </template>
                <template v-slot:toolsbar>
                    <Button
                        type="info"
                        style="margin-right: 10px"
                        @click="
                            setNetwork.tip = true
                            closeNetwork(false)
                        "
                    >
                        取消
                    </Button>
                    <Button type="primary" @click="saveNetwork">确定</Button>
                </template>
            </LiefengModal>
            <!--设置网格员-->
            <LiefengModal title="设置网格员" class="group" :value="setPeople.status" @input="closeSetPeople" :fullscreen="true">
                <template v-slot:contentarea>
                    <Selecttable v-if="selectTrue" @selectList="selectList" :selectOrg="selectOrg" :gridId="seleGrid" :showNum="showNumSelect"></Selecttable>
                </template>
                <template v-slot:toolsbar>
                    <Button
                        type="info"
                        style="margin-right: 10px"
                        @click="
                            setPeople.tip = true
                            closeSetPeople(false)
                        "
                    >
                        取消
                    </Button>
                    <Button type="primary" :loading="addLoading" @click="saveSetPeople">确定</Button>
                </template>
            </LiefengModal>

            <!--网上建设总览-->
            <LiefengModal title="网格建设总览" :value="constructionStatus" @input="closeConstruction" :fullscreen="true">
                <template v-slot:contentarea>
                    <Construction v-if="showConstruction" :communityCode="$store.state.gridCommunityCode"></Construction>
                </template>
                <template v-slot:toolsbar>
                    <Button type="primary" @click="constructionReally">确定</Button>
                </template>
            </LiefengModal>
        </template>
    </LiefengContent>
</template>

<script>
//@route("/gridindex")
import LiefengContent from "@/components/LiefengContent3"
import LiefengModal from "@/components/LiefengModal"
import LiefengTable from "@/components/LiefengTable"
import Addgrid from "./children/addgrid"
import Tree from "./children/tree"
import Selecttable from "./children/selecttable"
import LiefengCascaderOne from "./children/LiefengCascaderOne"
import LiefengCascaderCity from "@/components/LiefengCascaderCity"
import Construction from "./children/construction.vue"
export default {
    data() {
        return {
            communityCode: "",
            // 是否清空组件
            isClear: false,
            // 选择后的选择code
            cascaderList: [],
            showNum: 0,
            searchData: {},
            loading: false,
            curPage: 0,
            total: 0,
            pageSize: 20,

            tableData: [],
            talbeColumns: [
                {
                    type: "selection",
                    width: 60,
                    align: "center",
                },
                {
                    title: "区",
                    width: 80,
                    key: "zone",
                    align: "center",
                },
                {
                    title: "街道",
                    key: "street",
                    minWidth: 200,
                    align: "center",
                },
                {
                    title: "社区",
                    key: "orgName",
                    minWidth: 200,
                    align: "center",
                },
                {
                    title: "网格编码",
                    key: "gridName",
                    align: "center",
                    minWidth: 170,
                },
                {
                    title: "简称",
                    key: "gridAlias",
                    align: "center",
                    minWidth: 170,
                },
                {
                    title: "覆盖门牌号",
                    key: "regionCount",
                    align: "center",
                    width: 100,
                },
                {
                    title: "基础网格边界说明",
                    align: "center",
                    minWidth: 80,
                    key: "gridDesc",
                },
                {
                    title: "是否已建工作群",
                    align: "center",
                    width: 80,
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: {
                                    color: params.row.chatSecondAmount ? (params.row.chatSecondAmount == 0 ? "red" : "") : "red",
                                },
                            },
                            params.row.chatSecondAmount ? (params.row.chatSecondAmount == 0 ? "否" : "是") : "否"
                        )
                    },
                },
                {
                    title: "是否已建沟通组",
                    align: "center",
                    width: 80,
                    render: (h, params) => {
                        return h("div", {}, params.row.chatAmount ? (params.row.chatAmount == 0 ? "否" : "是") : "否")
                    },
                },
                {
                    title: "网格员",
                    minWidth: 200,
                    align: "center",
                    render: (h, params) => {
                        return h("div", {}, params.row.staffList ? params.row.staffList.join(",") : "")
                    },
                },
                {
                    title: "操作",
                    align: "center",
                    width: 150,
                    fixed: "right",
                    render: (h, params) => {
                        return h("div", [
                            h(
                                "Dropdown",
                                {
                                    props: {
                                        transfer: true,
                                    },
                                },
                                [
                                    h(
                                        "Button",
                                        {
                                            props: {
                                                type: "info",
                                                size: "small",
                                                icon: "ios-arrow-down",
                                            },
                                        },
                                        "编辑"
                                    ),
                                    h(
                                        "DropdownMenu",
                                        {
                                            slot: "list",
                                        },
                                        [
                                            h(
                                                "DropdownItem",
                                                {
                                                    nativeOn: {
                                                        click: () => {
                                                            this.showNum = this.showNum + 1
                                                            this.setNetwork.status = true
                                                            this.dscString = ""
                                                            this.treeOrgCode = params.row.orgCode
                                                            this.dscString = params.row.gridDesc
                                                            this.gridId = ""
                                                            this.gridId = params.row.gridId
                                                            this.getGridDetail(params.row.gridId)
                                                        },
                                                    },
                                                    style: {
                                                        textAlign: "center",
                                                    },
                                                },
                                                "设置网格边界"
                                            ),
                                            h(
                                                "DropdownItem",
                                                {
                                                    nativeOn: {
                                                        click: () => {
                                                            this.selectOrg = ""
                                                            this.seleGrid = ""
                                                            this.seleGrid = params.row.gridId
                                                            this.selectOrg = params.row.orgCode
                                                            ++this.showNumSelect
                                                            this.setPeopleBtn()
                                                        },
                                                    },
                                                    style: {
                                                        textAlign: "center",
                                                    },
                                                },
                                                "设置网格员"
                                            ),
                                            h(
                                                "DropdownItem",
                                                {
                                                    nativeOn: {
                                                        click: () => {
                                                            this.editType = "edit"
                                                            this.editRow = {}
                                                            this.editRow = params.row
                                                            this.showIcon = false
                                                            this.editBtn()
                                                        },
                                                    },
                                                    style: {
                                                        textAlign: "center",
                                                    },
                                                },
                                                "修改"
                                            ),
                                            h(
                                                "DropdownItem",
                                                {
                                                    nativeOn: {
                                                        click: () => {
                                                            this.$Modal.confirm({
                                                                title: "温馨提示",
                                                                content: "是否删除此条数据",
                                                                onOk: () => {
                                                                    // this.$post("/gx/pc/grid/deleteGrid", {
                                                                    this.$post("/syshequ/api/sytop/pc/grid/deleteGrid", {
                                                                        gridId: params.row.gridId,
                                                                    }).then(res => {
                                                                        if (res.code == 200) {
                                                                            this.$Message.success({
                                                                                content: "删除成功",
                                                                                background: true,
                                                                            })
                                                                            let data = {
                                                                                page: this.curPage,
                                                                                pageSize: this.pageSize,
                                                                                orgCode:
                                                                                    this.cascaderList.length != 0 && this.cascaderList.length == 3
                                                                                        ? this.cascaderList[this.cascaderList.length - 1]
                                                                                        : "",
                                                                            }

                                                                            this.getList(data)
                                                                            this.selectIsTrue = true
                                                                            this.selectCityOper.city = ""
                                                                            this.selectCityOper.region = ""
                                                                            this.selectCityOper.stree = ""
                                                                            this.selectCityOper.project = ""
                                                                            this.searchData.groupName = ""
                                                                            this.searchData.createdGroup = ""
                                                                            return
                                                                        } else {
                                                                            this.$Message.error({
                                                                                content: "删除成功",
                                                                                background: true,
                                                                            })
                                                                            return
                                                                        }
                                                                    })
                                                                },
                                                            })
                                                        },
                                                    },
                                                    style: {
                                                        textAlign: "center",
                                                    },
                                                },
                                                "删除"
                                            ),
                                        ]
                                    ),
                                ]
                            ),
                        ])
                    },
                },
            ],
            addModel: {
                addGridStatus: false,
                cancelGridTip: false,
            },
            codeList: [{ code: "", aliases: "" }],
            cityOper: {
                city: "",
                region: "",
                stree: "",
                project: "",
            },
            // 新增网格是否打开，是就清空
            isTrue: false,
            // 新增网格边界模态框参数
            setNetwork: {
                status: false,
                tip: false,
            },
            // 这是网格员模态框参数
            setPeople: {
                status: false,
                tip: false,
            },
            // 获取修改的网格数据
            editRow: {},
            // 是新增还是修改网格
            isEdit: false,
            gridId: "",
            // 设置边界内容
            gridDesc: "",
            // 传给树结构的社区code
            treeOrgCode: "",
            // 已选的子节点的code
            treeListCode: [],
            treeString: "",
            // 设置户代表的表格
            seleGrid: "",
            //设置网络员orgCode
            selectOrg: "",
            // 确定已选择的
            allSelectList: [],
            // 树结构的说明文字
            dscString: "",
            selectTrue: true,
            // 选择创建沟通组存放的数组
            selectGroup: [],
            firstOrgCode: "",
            noMany: false,
            //分页选择地区的
            selectIsTrue: false,
            selectCityOper: {
                city: "",
                region: "",
                stree: "",
                project: "",
            },
            createdGroupList: [
                { label: "是", value: "1" },
                { label: "否", value: "0" },
            ],

            gridType: "1",
            selectListTree: [],
            selectListArr: "",

            // 网上建设总览
            constructionStatus: false,
            showConstruction: false,

            showIcon: false,

            showNumSelect: 0,
            addLoading: false,

            // 编辑状态
            editType: "",

            // 初始化默认社区
            orgCodeValue: sessionStorage.getItem("RESIDENT_DATABASE_ORGCODE") || null,
        }
    },
    methods: {
        // 返回事件
        backClick() {
            if (this.$route.query.isLineHome && !this.$route.query.isback) this.$router.push("/residentdatabase")
            if (this.$route.query.isLineHome && this.$route.query.isback) this.$router.push(`/mobilizationsystem?isLineHome=${this.$route.query.isLineHome}`)
        },
        // 城市改变的事件
        changeCasder(val) {
            console.log(val)
            if (val) {
                this.cascaderList = val
                if (this.cascaderList && this.cascaderList.length == 3) {
                    let data = {
                        page: 1,
                        pageSize: 20,
                        orgCode: this.cascaderList[this.cascaderList.length - 1],
                    }
                    this.getList(data)
                }
            } else {
                let data = {
                    page: 1,
                    pageSize: 20,
                }
                this.getList(data)
            }
        },
        // 网上家园确定按钮
        constructionReally() {
            this.$store.commit("changeGridCommunityCode", "")
            this.constructionStatus = false
            this.showConstruction = false
        },
        // 关闭网上建设总览事件
        closeConstruction(status) {
            if (!status) {
                this.$store.commit("changeGridCommunityCode", "")
            }
            this.constructionStatus = status
            this.showConstruction = false
        },
        // 点击了网上建设总览按钮
        openConstruction() {
            if (this.communityCode) {
                console.log(this.communityCode)
                this.$store.commit("changeGridCommunityCode", this.communityCode)
                console.log(this.$store.state.gridCommunityCode)
                this.constructionStatus = true
                this.$nextTick(() => {
                    this.showConstruction = true
                })
            }
        },
        changeRadio(val) {
            this.gridType = val
        },
        tableSelectTree(val) {
            this.selectListTree = []
            if (val.length != 0) {
                val.map(item => {
                    this.selectListTree.push(item.gridId)
                })
            }
        },
        // 方法部分
        changeSelectLocal(val) {
            this.selectCityOper.city = val.city
            this.selectCityOper.region = val.region
            this.selectCityOper.stree = val.stree
            this.selectCityOper.project = val.project
        },
        formatterArr(arr, index) {
            this.firstOrgCode = arr.shift()
            if (arr.length >= 1) {
                for (var i = 0; i < arr.length; i++) {
                    if (arr[i] != this.firstOrgCode) {
                        this.$Message.warning({
                            content: "请选择同一社区下的网格",
                            background: true,
                        })
                        break
                    }
                }
            }
        },
        // 创建网格管理群
        addGridAdmin() {
            if (this.selectGroup.length == 0) {
                this.$Message.warning({
                    content: "请选择网格",
                    background: true,
                })
            } else {
                this.$Modal.confirm({
                    content: "<p>是否创建第二工作群，如已创建，则不会重复执行</p>",
                    title: "创建网格管理群",
                    onOk: () => {
                        let List = []
                        this.selectGroup.map(item => {
                            List.push(item.gridId)
                        })
                        let data = {
                            gridIdList: List,
                        }
                        this.createGroupGrid(data, 16)
                    },
                })
            }
        },
        // 点击了创建网格沟通组按钮
        addGridGroup() {
            if (this.selectGroup.length == 0) {
                this.$Message.warning({
                    content: "请选择网格",
                    background: true,
                })
            } else {
                this.$Modal.confirm({
                    content: "<p>是否创建网格沟通组，如已创建，则不会重复执行</p>",
                    title: "创建网格沟通组",
                    onOk: () => {
                        let List = []
                        this.selectGroup.map(item => {
                            List.push(item.gridId)
                        })
                        let data = {
                            gridIdList: List,
                        }
                        this.createGroupGrid(data, 13)
                    },
                })
            }
        },
        // 表格内容选择了之后
        tableSelect(val) {
            this.selectGroup = val
        },
        reset() {
            this.cascaderList = []
            this.isClear = !this.isClear
            this.selectIsTrue = true
            this.selectCityOper.city = ""
            this.selectCityOper.region = ""
            this.selectCityOper.stree = ""
            this.selectCityOper.project = ""
            this.searchData.groupName = ""
            this.searchData.createdGroup = ""
            let data = {
                gridOperator: this.searchData.groupName,
                page: 1,
                pageSize: 20,
                orgCode: "",
            }
            this.getList(data)
        },
        searchGroupName() {
            let lastCode = ""
            if (this.cascaderList.length != 0 && this.cascaderList.length != 3) {
                this.$Message.warning({
                    content: "请选择到社区再进行查询",
                    background: true,
                })
                return
            } else {
                lastCode = this.cascaderList[this.cascaderList.length - 1]
            }

            // if (this.selectCityOper.city && this.selectCityOper.city != "") {
            //   lastCode = this.selectCityOper.city.split("-").pop();
            //   if (this.selectCityOper.region && this.selectCityOper.region != "") {
            //     lastCode = this.selectCityOper.region.split("-").pop();
            //     if (this.selectCityOper.stree && this.selectCityOper.stree != "") {
            //       lastCode = this.selectCityOper.stree.split("-").pop();
            //       if (
            //         this.selectCityOper.project &&
            //         this.selectCityOper.project != ""
            //       ) {
            //         lastCode = this.selectCityOper.project.split("-").pop();
            //       }
            //     }
            //   }
            // }
            let data = {
                gridOperator: this.searchData.groupName,
                page: 1,
                pageSize: 20,
                orgCode: lastCode,
                createdGroup: this.searchData.createdGroup,
            }
            this.getList(data)
        },
        //  获取树节点的数据
        selectTree(val) {
            this.treeListCode = []
            this.treeString = ""
            val.map(item => {
                if (item.parentId != "0" && item.children.length == 0 && item.disabled != true) {
                    this.treeListCode.push(item.id)
                }
            })
            this.treeString = this.treeListCode.join(",")
        },
        // 网格边界模态框确定按钮
        saveSetPeople() {
            let List = []
            if (this.allSelectList.length == 0) {
                this.$Message.warning({
                    content: "请选择网格员",
                    background: true,
                })
                return
            } else {
                this.allSelectList.map(item => {
                    List.push(item.custGlobalId)
                })
            }
            let data = {
                custGlobalIds: List.join(","),
                gridId: this.seleGrid,
            }
            this.addGridOperator(data)
        },
        selectList(val) {
            this.allSelectList = val
        },
        // 关闭设置网络边界模态框
        closeSetPeople(status) {
            if (!status && this.setPeople.tip) {
                // this.$Modal.confirm({
                //   title: "温馨提示",
                //   content: "您正在关闭此窗口，信息将不会保存，是否继续",
                //   onOk: () => {
                this.setPeople.status = status
                this.selectOrg = ""
                this.seleGrid = ""
                this.allSelectList = ""
                //   }
                // });
            } else {
                this.setPeople.status = status
            }
        },
        // 点击设置网格员按钮
        setPeopleBtn() {
            this.setPeople.status = true
        },
        // 点击修改按钮
        editBtn() {
            // 先把东西清空一下

            this.codeList = [{ code: "", aliases: "" }]
            this.cityOper = {
                city: "",
                region: "",
                stree: "",
                project: "",
            }
            // 这里在赋值
            this.codeList = []
            this.cityOper.city = "44-4401"
            if (this.editRow.zoneCode && this.editRow.zoneCode != "") {
                this.cityOper.region = this.cityOper.city + "-" + this.editRow.zoneCode
                this.cityOper.stree = this.cityOper.region + "-" + this.editRow.streetCode
                this.cityOper.project = this.cityOper.stree + "-" + this.editRow.orgCode
            }
            // if (this.editRow.streetCode && this.editRow.streetCode != "") {
            //   this.cityOper.stree =
            //     this.cityOper.region + "-" + this.editRow.streetCode;
            // }
            // if (this.editRow.orgCode && this.editRow.orgCode != "") {
            //   this.cityOper.project =
            //     this.cityOper.stree + "-" + this.editRow.orgCode;
            // }
            if (this.editRow.gridName && this.editRow.gridName != "") {
                let list = this.editRow.gridName.split(",")
                list.map(item => {
                    this.codeList.push({ code: item })
                })
            }
            this.codeList[0].aliases = this.editRow.gridAlias || ""
            this.isEdit = true
            this.isTrue = false
            this.addModel.addGridStatus = true
        },
        // 点击确认设置网格边界按钮
        saveNetwork() {
            this.gridDesc = this.$refs.treeChild.gridDesc
            // if(this.$refs.treeChild.gridDesc == ''){
            //   this.$Message.warning({
            //       content: "请填写基础网格边界说明",
            //       background: true
            //     });
            //     return;
            // }
            console.log("this.treeListCode", this.treeListCode)
            if (this.gridType == "1") {
                if (this.treeListCode.length == 0) {
                    this.$Message.warning({
                        content: "请选择区域并选择到楼栋或者房号",
                        background: true,
                    })
                    return
                }

                this.addGridRegion()
            } else if (this.gridType == "2") {
                if (this.selectListTree.length == 0) {
                    this.$Message.warning({
                        content: "请选择楼组长",
                        background: true,
                    })
                    return
                }
                this.addGridLeader()
            }
        },
        // 关闭设置网络边界模态框
        closeNetwork(status) {
            if (!status && this.setNetwork.tip) {
                // this.$Modal.confirm({
                //   title: "温馨提示",
                //   content: "您正在关闭此窗口，信息将不会保存，是否继续",
                //   onOk: () => {
                this.setNetwork.status = status
                // this.$refs.treeChild.gridDesc = "";
                this.gridDesc = ""
                this.treeListCode = []
                this.gridId = ""
                this.selectTrue = false
                this.$nextTick(() => {
                    this.selectTrue = true
                })
                this.gridType = "1"
                this.selectListTree = []
                //   }
                // });
            } else {
                this.setNetwork.status = status
            }
        },
        // 点击新增网格按钮
        addGrid() {
            this.editType = "add"
            this.isEdit = false
            this.isTrue = true
            this.addModel.addGridStatus = true
            this.codeList = [{ code: "", aliases: "" }]
            this.cityOper = {
                city: "",
                region: "",
                stree: "",
                project: "",
            }
            this.showIcon = true
        },
        // table表改变页数事件
        hadlePageSize(data) {
            console.log(data)
            let reg = {
                page: data.page,
                pageSize: data.pageSize,
                orgCode: this.communityCode,
            }
            this.getList(reg)
        },
        // 新增网格模态框关闭事件
        addGridStatusFn(status) {
            if (!status && this.addModel.cancelGridTip) {
                // this.$Modal.confirm({
                //   title: "温馨提示",
                //   content: "您正在关闭此窗口，信息将不会保存，是否继续",
                //   onOk: () => {
                this.isTrue = false
                this.addModel.addGridStatus = status
                this.cityOper = {
                    city: "",
                    region: "",
                    stree: "",
                    project: "",
                }
                //   }
                // });
            } else {
                this.addModel.addGridStatus = status
            }
        },
        // 新增网格保存的按钮
        saveGrid() {
            if (this.cityOper.city == "") {
                // 没有选地址就提示选择
                this.$Message.warning({
                    content: "请选择所属社区",
                    background: true,
                })
                return
            } else {
                let memberFromScope = ""
                if (this.cityOper.city) {
                    let data = this.cityOper.city.split("-")
                    memberFromScope = data[data.length - 1]
                    if (this.cityOper.region) {
                        let datas = this.cityOper.region.split("-")
                        memberFromScope = datas[datas.length - 1]
                        if (this.cityOper.stree) {
                            let datass = this.cityOper.stree.split("-")
                            memberFromScope = datass[datass.length - 1]
                            if (this.cityOper.project) {
                                let datasss = this.cityOper.project.split("-")
                                memberFromScope = datasss[datasss.length - 1]
                            } else {
                                this.$Message.warning({
                                    content: "请选择到社区",
                                    background: true,
                                })
                                return
                            }
                        } else {
                            this.$Message.warning({
                                content: "请选择到社区",
                                background: true,
                            })
                            return
                        }
                    } else {
                        this.$Message.warning({
                            content: "请选择到社区",
                            background: true,
                        })
                        return
                    }
                } else {
                    this.$Message.warning({
                        content: "请选择到社区",
                        background: true,
                    })
                    return
                }

                // if (this.codeList[0].code == "") {
                //   this.$Message.warning({
                //     content: "网格编码1不能为空",
                //     background: true
                //   });
                //   return;
                // } else {
                //   this.codeList = this.trimSplice(this.codeList);

                // }

                for (let i = 0; i < this.codeList.length; i++) {
                    if (!this.codeList[i].code || !this.codeList[i].aliases) {
                        this.$Message.warning({
                            content: "请补充完内容",
                            background: true,
                        })
                        return
                    }
                }

                // 如果有选项之后在进行处理
                // 点击新增
                if (!this.isEdit) {
                    let list = []
                    let gridAliases = []
                    this.codeList.map(item => {
                        list.push(item.code)
                        gridAliases.push(item.aliases)
                    })

                    let data = {
                        gridNames: list,
                        gridOperator: memberFromScope,
                        gridAliases: gridAliases,
                    }

                    this.addGridJie(data)
                } else {
                    // 点击修改的
                    let list = []
                    let arr = []
                    this.codeList.map(item => {
                        list.push(item.code)
                        arr.push(item.aliases)
                    })
                    let gridName = list.join(",")
                    let gridAlias = arr.join(",")
                    let data = {
                        gridId: this.editRow.gridId,
                        gridName: gridName,
                        gridAlias: gridAlias,
                        operatorOrgCode: memberFromScope,
                    }
                    this.updateGrid(data)
                }
            }
        },
        trimSplice(arr) {
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].code == "" || arr[i].code == null) {
                    arr.splice(i, 1)
                    i = i - 1
                }
            }
            return arr
        },
        // 接口部分
        getList(data) {
            console.log(data)
            this.communityCode = data.orgCode
            console.log(parent.vue.loginInfo.userinfo.realName)
            console.log(data)
            this.loading = true
            this.$get("/syshequ/api/sytop/pc/grid/queryGridPage", {
                createdGroup: data.createdGroup,
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                gridOperator: data.gridOperator,
                oemCode: parent.vue.oemInfo.oemCode,
                operatorOrgCode: parent.vue.loginInfo.userinfo.orgCode, //获取地区的编码
                orgCode: data.orgCode,
                page: data.page,
                pageSize: data.pageSize,
                staffName: parent.vue.loginInfo.userinfo.realName,
                gridType: 1,
            }).then(res => {
                this.loading = false
                if (res.code == 200) {
                    let data = res.dataList
                    // data.map(item => {
                    //   if (item.createdGroup && item.createdGroup == "1") {
                    //     item._disabled = true;
                    //   }
                    // });

                    this.tableData = data
                    this.total = res.maxCount
                    this.curPage = res.currentPage
                } else {
                    this.$Message.error({
                        content: "获取数据失败",
                        background: true,
                    })
                }
            })
        },
        // 新增网格接口
        addGridJie(data) {
            this.$post("/gx/pc/grid/addGrid", {
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                gridNames: data.gridNames,
                gridAliases: data.gridAliases,
                oemCode: parent.vue.oemInfo.oemCode,
                operatorOrgCode: parent.vue.loginInfo.userinfo.orgCode,
                orgCode: data.gridOperator,
                staffName: parent.vue.loginInfo.userinfo.realName,
                gridType: 1,
                parentId: data.gridOperator + "-" + parent.vue.oemInfo.oemCode,
            }).then(res => {
                if (res.code == 200) {
                    this.$Message.success({
                        content: "新增网格成功",
                        background: true,
                    })
                    let reg = {
                        page: this.curPage,
                        pageSize: this.pageSize,
                        orgCode: this.cascaderList.length != 0 && this.cascaderList.length == 3 ? this.cascaderList[this.cascaderList.length - 1] : "",
                    }
                    this.getList(reg)
                    this.addModel.addGridStatus = false
                    this.addModel.cancelGridTip = false
                    this.isTrue = false
                    this.selectIsTrue = true
                    this.selectCityOper.city = ""
                    this.selectCityOper.region = ""
                    this.selectCityOper.stree = ""
                    this.selectCityOper.project = ""
                    this.searchData.groupName = ""
                    this.searchData.createdGroup = ""
                    // this.cascaderList = []
                    // this.isClear = !this.isClear
                    return
                } else {
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                    this.isTrue = false
                    return
                }
            })
        },
        // 修改网格接口
        updateGrid(data) {
            this.$post("/gx/pc/grid/updateGrid", {
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                gridId: data.gridId,
                gridName: data.gridName,
                gridAlias: data.gridAlias,
                oemCode: parent.vue.oemInfo.oemCode,
                operatorOrgCode: parent.vue.loginInfo.userinfo.orgCode,
                orgCode: data.operatorOrgCode,
                staffName: parent.vue.loginInfo.userinfo.realName,
            }).then(res => {
                if (res.code == 200) {
                    this.$Message.success({
                        content: "修改网格成功",
                        background: true,
                    })
                    let reg = {
                        page: this.curPage,
                        pageSize: this.pageSize,
                        orgCode: this.cascaderList.length != 0 && this.cascaderList.length == 3 ? this.cascaderList[this.cascaderList.length - 1] : "",
                    }
                    this.getList(reg)
                    this.addModel.addGridStatus = false
                    this.addModel.cancelGridTip = false
                    this.isTrue = false
                    this.selectIsTrue = true
                    this.selectCityOper.city = ""
                    this.selectCityOper.region = ""
                    this.selectCityOper.stree = ""
                    this.selectCityOper.project = ""
                    this.searchData.groupName = ""
                    this.searchData.createdGroup = ""
                    this.cityOper = {
                        city: "",
                        region: "",
                        stree: "",
                        project: "",
                    }
                    return
                } else {
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                    return
                }
            })
        },
        // 设置网格区域
        addGridRegion() {
            this.$post("/gx/pc/grid/addGridRegion", {
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                gridDesc: this.gridDesc,
                gridId: this.gridId,
                oemCode: parent.vue.oemInfo.oemCode,
                operatorOrgCode: parent.vue.loginInfo.userinfo.orgCode,
                regionIds: this.treeListCode.join(","),
                staffName: parent.vue.loginInfo.userinfo.realName,
            }).then(res => {
                if (res.code == 200) {
                    this.$Message.success({
                        content: "设置网格边界成功",
                        background: true,
                    })
                    // this.$refs.treeChild.gridDesc = "";
                    this.gridDesc = ""
                    this.treeListCode = []
                    this.gridId = ""
                    this.setNetwork = {
                        status: false,
                        tip: false,
                    }
                    let reg = {
                        page: this.curPage,
                        pageSize: this.pageSize,
                        orgCode: this.cascaderList.length != 0 && this.cascaderList.length == 3 ? this.cascaderList[this.cascaderList.length - 1] : "",
                    }
                    this.getList(reg)
                    this.selectIsTrue = true
                    this.selectCityOper.city = ""
                    this.selectCityOper.region = ""
                    this.selectCityOper.stree = ""
                    this.selectCityOper.project = ""
                    this.searchData.groupName = ""
                    this.searchData.createdGroup = ""
                    this.selectListTree = []
                    // this.cascaderList = []
                    // this.isClear = !this.isClear
                    return
                } else {
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })

                    return
                }
            })
        },
        // 设置网格员
        addGridOperator(data) {
            this.addLoading = true
            console.log(data.custGlobalIds)
            this.$post("/syshequ/api/sytop/pc/grid/addGridOperator", {
                gridId: data.gridId,
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                custGlobalIds: data.custGlobalIds,
                oemCode: parent.vue.oemInfo.oemCode,
                operatorOrgCode: parent.vue.loginInfo.userinfo.orgCode,
                staffName: parent.vue.loginInfo.userinfo.realName,
                groupType: 16,
            }).then(res => {
                if (res.code == 200) {
                    this.addLoading = false
                    this.$Message.success({
                        content: "保存成功",
                        background: true,
                    })
                    this.setPeople = {
                        status: false,
                        tip: false,
                    }
                    let reg = {
                        page: this.curPage,
                        pageSize: this.pageSize,
                        orgCode: this.cascaderList.length != 0 && this.cascaderList.length == 3 ? this.cascaderList[this.cascaderList.length - 1] : "",
                    }
                    this.getList(reg)
                    this.selectIsTrue = true
                    this.selectCityOper.city = ""
                    this.selectCityOper.region = ""
                    this.selectCityOper.stree = ""
                    this.selectCityOper.project = ""
                    this.searchData.groupName = ""
                    this.searchData.createdGroup = ""
                    return
                } else {
                    this.addLoading = false
                    this.$Message.error({
                        content: "保存失败",
                        background: true,
                    })
                    return
                }
            })
        },
        // 创建网格沟通组接口
        createGroupGrid(data, type) {
            this.$post(
                "/sendmsg/pc/chat/grid/createGroupGrid",
                {
                    createMode: "2",
                    creatorId: parent.vue.loginInfo.userinfo.custGlobalId,
                    creatorName: parent.vue.loginInfo.userinfo.realName,
                    gridIdList: data.gridIdList,
                    groupType: type,
                    oemCode: parent.vue.oemInfo.oemCode,
                    // orgCode: parent.vue.loginInfo.userinfo.orgCode,
                    orgCodeCreate: parent.vue.loginInfo.userinfo.orgCode,
                },
                { "Content-Type": "application/json" }
            ).then(res => {
                if (res.code == 200) {
                    this.$Message.success({
                        content: "创建成功",
                        background: true,
                    })
                    this.selectGroup = []
                    let reg = {
                        page: this.curPage,
                        pageSize: this.pageSize,
                        orgCode: this.cascaderList.length != 0 && this.cascaderList.length == 3 ? this.cascaderList[this.cascaderList.length - 1] : "",
                    }
                    this.getList(reg)
                    this.selectIsTrue = true
                    this.selectCityOper.city = ""
                    this.selectCityOper.region = ""
                    this.selectCityOper.stree = ""
                    this.selectCityOper.project = ""
                    this.searchData.groupName = ""
                    this.searchData.createdGroup = ""
                    // this.cascaderList = []
                    // this.isClear = !this.isClear
                } else {
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                    return
                }
            })
        },
        // 设置楼组长接口
        addGridLeader() {
            this.$post("/gx/pc/grid/bindGrid", {
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                gridId: this.gridId,
                gridDesc: this.gridDesc,
                oemCode: parent.vue.oemInfo.oemCode,
                operatorOrgCode: parent.vue.loginInfo.userinfo.orgCode,
                staffName: parent.vue.loginInfo.userinfo.realName,
                grids: this.selectListTree.join(","),
            }).then(res => {
                if (res.code == 200) {
                    this.$Message.success({
                        content: "设置网格边界成功",
                        background: true,
                    })
                    // this.$refs.treeChild.gridDesc = "";
                    this.gridDesc = ""
                    this.treeListCode = []
                    this.gridId = ""
                    this.setNetwork = {
                        status: false,
                        tip: false,
                    }
                    let reg = {
                        page: this.curPage,
                        pageSize: this.pageSize,
                        orgCode: this.cascaderList.length != 0 && this.cascaderList.length == 3 ? this.cascaderList[this.cascaderList.length - 1] : "",
                    }
                    this.getList(reg)
                    this.selectIsTrue = true
                    this.selectCityOper.city = ""
                    this.selectCityOper.region = ""
                    this.selectCityOper.stree = ""
                    this.selectCityOper.project = ""
                    this.searchData.groupName = ""
                    this.searchData.createdGroup = ""
                    this.selectListTree = []
                    return
                } else {
                    this.$Message.error({
                        content: "设置网格边界失败",
                        background: true,
                    })

                    return
                }
            })
        },
        // 获取网格详情
        async getGridDetail(data) {
            await this.$get("/gx/pc/grid/selectGridInfo", {
                gridId: data,
            }).then(res => {
                if (res.code == 200) {
                    this.selectListArr = ""
                    console.log("resdata", res.data.childIdList)
                    if (res.data.childIdList && res.data.childIdList.length != 0) {
                        this.selectListTree = res.data.childIdList
                        this.selectListArr = res.data.childIdList.join(",")
                        console.log("详情接口获取的selectListArr", this.selectListArr)
                        this.gridType = "2"
                    }
                } else {
                    this.$Message.error({
                        content: "获取网格详情失败",
                        background: true,
                    })
                    return
                }
            })
        },
    },
    created() {
        if (this.$store.state.gridCommunityCode) {
            this.constructionStatus = true
            this.$nextTick(() => {
                this.showConstruction = true
            })
        }
        // let data = {
        //   page: 1,
        //   pageSize: 20
        // };
        // this.getList(data);
    },
    components: {
        LiefengContent,
        LiefengModal,
        LiefengTable,
        Addgrid,
        Tree,
        Selecttable,
        LiefengCascaderOne,
        Construction,
        LiefengCascaderCity,
    },
}
</script>

<style scoped lang="less">
.group {
    /deep/.ivu-modal-close {
        display: none;
    }
}
// /deep/#toolsbarRight {
//   width: 1000%;
.cascader {
    width: 500px;
}
// }
</style>
