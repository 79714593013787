<template>
  <div>
    <Form label-position="left" :label-width="100" style="width:50%">
      <FormItem>
        <span slot="label" class="validate">所属社区:</span>
        <div class="cascader-div">
          <Select
            :disabled="editType == 'add' ? false : true"
            v-model="cityOper.city"
            transfer
            style="width: 20%;margin-right:10px"
            :max-tag-count="1"
            placeholder="请选择市"
            filterable
            @on-change="changeCity"
          >
            <Option
              :value="item.value"
              v-for="(item, index) in cityList"
              :key="index"
            >{{ item.label }}</Option>
          </Select>
          <Select
            
            v-model="cityOper.region"
            transfer
            style="width: 20%;margin-right:10px"
            :max-tag-count="1"
            placeholder="请选择区"
            :disabled="editType == 'add' ? false : true"
            filterable
            @on-change="changeRegion"
          >
            <Option
              :value="item.value"
              v-for="(item, index) in regionList"
              :key="index"
            >{{ item.label }}</Option>
          </Select>
          <Select
            v-model="cityOper.stree"
            transfer
            style="width: 20%;margin-right:10px"
            :max-tag-count="1"
            placeholder="请选择街道/乡镇"
            :disabled="editType == 'add' ? false : true"
            filterable
            @on-change="changeStree"
          >
            <Option
              :value="item.value"
              v-for="(item, index) in streeList"
              :key="index"
            >{{ item.label }}</Option>
          </Select>
          <Select
            v-model="cityOper.project"
            transfer
            style="width: 20%"
            :max-tag-count="1"
            placeholder="请选择社区"
            :disabled="editType == 'add' ? false : true"
            filterable
            @on-change="changeProject"
          >
            <Option
              :value="item.value"
              v-for="(item, index) in projectList"
              :key="index"
            >{{ item.label }}</Option>
          </Select>
        </div>
      </FormItem>
      <FormItem v-for="(item,index) in codeList" :key="index">
        <span slot="label" class="validate">网格编码{{index + 1}}:</span>
        <Input
          style="width:80%;margin-top:-12px"
          placeholder="限50个字"
          v-model.trim="item.code"
          :maxlength="50"
        ></Input>
        <span style="display:inline-block;margin-top: 20px;" slot="label" class="validate">网格简称{{index + 1}}:</span>
        <Input
          style="width:60%"
          placeholder="限20个字"
          v-model.trim="item.aliases"
          :maxlength="20"
        ></Input>
        <span v-if="index == 0 && showIcon == true" style="font-size:30px;margin-left:5px" >
          <Icon  type="ios-add-circle" @click="addCode" />
        </span>
        <span v-if="index != 0 && showIcon == true" style="font-size:30px;margin-left:5px">
          <Icon  type="ios-close-circle" @click="minuCode(index)" />
        </span>
      </FormItem>
    </Form>
  </div>
</template>

<script>
export default {
  props: {
    cityOper: {
      type: Object,
      default: () => {
        return {};
      }
    },
    codeList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    isTrue: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    showIcon:{
      type: Boolean,
      default: () => {
        return false;
      }
    },
    editType:{
      type: String,
      default: () => {
        return '';
      }
    }


  },
  data() {
    //这里存放数据
    return {
      cityDisabled: false,
      cityList: [],
      regionDisabled: true, //区域禁止项
      regionList: [], //存放城市列表
      streeDisabled: true, //街道禁止项
      streeList: [],
      projectDisabled: true, //社区禁止项
      projectList: [],
      isNum:0
    };
  },
  //方法集合
  methods: {
    //   点击添加按钮
    addCode() {
      this.codeList.push({ code: "",aliases:'' });
    },
    minuCode(index) {
      this.codeList.splice(index, 1);
    },
    changeLocal(val) {
      this.cityOper.city = val.city;
      this.cityOper.region = val.region;
      this.cityOper.stree = val.stree;
      this.cityOper.project = val.project;
    },
    changeCity(code) {
      if (code) {
        this.regionDisabled = false;
        let arrCode = code.split("-");
        this.getPulishData(arrCode[arrCode.length - 1], "regionList", "region");
      }

      //   this.$emit("changeLocal", this.cityOper);
    },
    // 选择区的回调事件
    changeRegion(code) {
      if (code) {
        let arrCode = code.split("-");
        this.streeDisabled = false;
       
        this.projectDisabled = true
        this.getPulishData(arrCode[arrCode.length - 1], "streeList", "stree");
      }

      //   this.$emit("changeLocal", this.cityOper);
    },
    // 选择街道的回调事件
    changeStree(code) {
      if (code) {
        let arrCode = code.split("-");
        this.projectDisabled = false;
        //  this.cityOper.project = ''
        this.getPulishData(
          arrCode[arrCode.length - 1],
          "projectList",
          "project"
        );
      }

      //   this.$emit("changeLocal", this.cityOper);
    },
    changeProject() {
        this.$emit("changeLocal", this.cityOper);
    },
    // 获取地址的公共方法
    // code:传进去的获取的code码，list:传进去的城市地址 , model：
    getPulishData(code, list, model) {
      if (!this.isEdit) {
        this.$get("/datamsg/api/pc/staff/selectCascadeDataScope", {
          orgCode: code,
          oemCode: parent.vue.oemInfo.oemCode,
          orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
          custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId
        }).then(res => {
          if (res.code == 200) {
            this[list] = res.dataList.map(item => {
              return {
                value: item.orgPath,
                label: item.orgName,
                orgLevel: item.orgLevel,
                code: item.orgCode
              };
            });
            if (res.dataList.length == "1") {
              this[model] = [res.dataList[0].orgPath];
            }
            if(res.dataList.length != 0){
              if(model == 'city' && this.isNum == 0){
                this.cityOper.city = this[list][0].value
                this.isNum = 1
                this.changeCity(this[list][0].value)
              }else if(model == 'region' && this.isNum == 1){
                this.isNum = 2
                this.cityOper.region = this[list][0].value
                this.changeRegion(this[list][0].value)
              }else if(model == 'stree' && this.isNum == 2){
                this.isNum = 3
                this.cityOper.stree = this[list][0].value
                this.changeStree(this[list][0].value)
              }else if(model == 'project' && this.isNum == 3){
                this.isNum = null
                this.cityOper.project = this[list][0].value
              }
            }
          }
        });
      }
    }
  },
  watch: {
    isTrue: {
      handler(val, newVal) {
        if (val) {
          this.cityDisabled = true;
          this.cityList = [];
          this.regionDisabled = true; //区域禁止项
          this.regionList = []; //存放城市列表
          this.streeDisabled = true; //街道禁止项
          this.streeList = [];
          this.projectDisabled = true; //社区禁止项
          this.projectList = [];
          this.isNum = 0
          this.getPulishData("44", "cityList", "city");
        }
      }
    },
    cityOper: {
      handler(val, newVal) {
        if (val) {
          setTimeout(() => {
            this.changeCity(val.city);
            this.changeRegion(val.region);
            this.changeStree(val.stree);
          }, 100);
        }
      },
      immediate: true
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getPulishData("44", "cityList", "city");
  }
};
</script>
<style lang='less' scoped>
//编写css
</style>