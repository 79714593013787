<!--选择街道单选-->
<!--
组件使用方法：
<LiefengCascaderOne></LiefengCascaderOne>
import LiefengCascaderOne from './children/LiefengCascaderOne'

参数：           介绍          默认值        引用
title         左侧标题       默认为空     :title=''
cityOper      绑定的数据     默认为空     :cityOper="cityOper"  city/region/stree/project
changeLocal   change事件                 @changeLocal='changeLocal' 
-->
<template>
  <div class="cascader-div">
    <span class="cascader-span" v-if="title">{{title}}</span>
    <Select
      v-model="cityOper.city"
      transfer
      style="width: 20%;margin-right:10px"
      :max-tag-count="1"
      placeholder="请选择市"
      filterable
      @on-change="changeCity"
    >
      <Option :value="item.value" v-for="(item, index) in cityList" :key="index">{{ item.label }}</Option>
    </Select>
    <Select
      v-model="cityOper.region"
      transfer
      style="width: 20%;margin-right:10px"
      :max-tag-count="1"
      placeholder="请选择区"
      :disabled="regionDisabled"
      filterable
      @on-change="changeRegion"
    >
      <Option :value="item.value" v-for="(item, index) in regionList" :key="index">{{ item.label }}</Option>
    </Select>
    <Select
      v-model="cityOper.stree"
      transfer
      style="width: 20%;margin-right:10px"
      :max-tag-count="1"
      placeholder="请选择街道/乡镇"
      :disabled="streeDisabled"
      filterable
      @on-change="changeStree"
    >
      <Option :value="item.value" v-for="(item, index) in streeList" :key="index">{{ item.label }}</Option>
    </Select>
    <Select
      v-model="cityOper.project"
      transfer
      style="width: 20%"
      :max-tag-count="1"
      placeholder="请选择社区"
      :disabled="projectDisabled"
      filterable
      @on-change="changeProject"
    >
      <Option :value="item.value" v-for="(item, index) in projectList" :key="index">{{ item.label }}</Option>
    </Select>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: () => {
        return "";
      }
    },
    cityOper: {
      type: Object,
      default: () => {
        return {};
      }
    },
    isEdit: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    isTrue: {
      type: Boolean,
      default: () => {
        return true;
      }
    }
  },
  data() {
    return {
      cityDisabled: false,
      cityList: [],
      regionDisabled: true, //区域禁止项
      regionList: [], //存放城市列表
      streeDisabled: true, //街道禁止项
      streeList: [],
      projectDisabled: true, //社区禁止项
      projectList: []
    };
  },
  methods: {
    changeCity(code) {
      if (code) {
        this.regionDisabled = false;
        let arrCode = code.split("-");
        this.getPulishData(arrCode[arrCode.length - 1], "regionList", "region");
      }

      this.$emit("changeLocal", this.cityOper);
    },
    // 选择区的回调事件
    changeRegion(code) {
      if (code) {
        let arrCode = code.split("-");
        this.streeDisabled = false;
        this.getPulishData(arrCode[arrCode.length - 1], "streeList", "stree");
      }

      this.$emit("changeLocal", this.cityOper);
    },
    // 选择街道的回调事件
    changeStree(code) {
      if (code) {
        let arrCode = code.split("-");
        this.projectDisabled = false;
        this.getPulishData(
          arrCode[arrCode.length - 1],
          "projectList",
          "project"
        );
      }

      this.$emit("changeLocal", this.cityOper);
    },
    changeProject() {
      this.$emit("changeLocal", this.cityOper);
    },
    // 获取地址的公共方法
    // code:传进去的获取的code码，list:传进去的城市地址 , model：
    getPulishData(code, list, model) {
      if (!this.isEdit) {
        this.$get("/datamsg/api/pc/staff/selectCascadeDataScope", {
          orgCode: code,
          oemCode: parent.vue.oemInfo.oemCode,
          orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
          custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId
        }).then(res => {
          if (res.code == 200) {
            this[list] = res.dataList.map(item => {
              return {
                value: item.orgPath,
                label: item.orgName,
                orgLevel: item.orgLevel,
                code: item.orgCode
              };
            });
            if (res.dataList.length == "1") {
              this[model] = [res.dataList[0].orgPath];
            }
            // if(this[list] && this[list].length != 0){
            //   if(model == 'city'){
            //     this.cityOper.region = this[list][0].value
            //     this.changeCity(this.cityOper.city)
            //   }else if(model == 'region'){
            //     this.cityOper.stree = this[list][0].value
            //     this.changeRegion(this.cityOper.region)
            //   }else if(model == 'stree'){
            //     this.cityOper.project = this[list][0].value
            //     this.changeStree(this.cityOper.stree)
            //   }
                
            // }
          }
        });
      }
    }
  },
  created() {
    this.getPulishData("44", "cityList", "city");
  },
  watch: {
    isTrue: {
      handler(val, newVal) {
        if (val) {
          this.cityDisabled = true;
          this.cityList = [];
          this.regionDisabled = true; //区域禁止项
          this.regionList = []; //存放城市列表
          this.streeDisabled = true; //街道禁止项
          this.streeList = [];
          this.projectDisabled = true; //社区禁止项
          this.projectList = [];
          this.getPulishData("44", "cityList", "city");
        }
      }
    }
  }
};
</script>

<style scoped lang='less'>
.cascader-div {
  .cascader-span {
    margin: 0 10px;
  }
}
</style>